<template>
  <div>
    <div class="c-row">
      <div class="c-col-full">
        <status-card
          :loading="loading"
          :value="recurrence.status"
        >
          <!-- Dados -->
          <div class="ui-p-6 c-bg-white">
            <!-- Vertical -->
            <c-skeleton-block
              v-if="loading"
              height="1rem"
              width="8rem"
            />
            <div
              v-else
              class="c-text-b2 c-text-gray"
            >
              {{ recurrence.titleVertical }}
            </div>

            <!-- Nome do produto -->
            <div
              class="c-d-flex c-vertical-center ui-mt-2"
              v-for="item of recurrence.orderItems"
              :key="item.id"
            >
              <div class="ui-w-1 ui-h-1 ui-mr-2 ui-rounded-lg c-bg-primary-200" />
              <span class="c-text-b1">{{ item.description }}</span>
            </div>

            <!-- Loader do nome do produto -->
            <template v-if="loading">
              <div class="c-d-flex c-vertical-center ui-mt-2">
                <div class="ui-w-1 ui-h-1 ui-mr-2 ui-rounded-lg c-bg-gray-200" />
                <c-skeleton-block
                  width="14rem"
                  height="1rem"
                />
              </div>
            </template>

            <!-- Separador -->
            <separator />

            <!-- Número do pedido -->
            <div>
              <template v-if="loading">
                <c-skeleton-block
                  height="1rem"
                  width="7rem"
                  class="ui-inline-flex ui-mr-1"
                />
                <c-skeleton-block
                  class="ui-inline-flex"
                  height="1rem"
                  width="6rem"
                />
              </template>
              <span
                v-else
                class="c-text-b2 c-text-gray ui-mr-1"
              >
                Pedido {{ recurrence.orderNumber }}.
              </span>

              <a
                :href="getRecurrenceLink(recurrence)"
                v-if="!loading"
                class="c-text-hyperlink"
              >
                Alterar cartão
              </a>
            </div>
          </div>
        </status-card>
      </div>
    </div>
  </div>
</template>

<script>
import StatusCard from './status-card';
import Separator from '@/components/separator';
import { CSkeletonBlock } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'RecurrenceCard',
  components: {
    StatusCard,
    Separator,
    CSkeletonBlock,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    recurrence: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getRecurrenceLink(recurrence) {
      const vertical = ((name) => {
        if (name === 'carreiras-juridicas') {
          return 'cj';
        }
        if (name === 'medicina') {
          return 'med';
        }
        return name;
      })(recurrence.vertical);
      return `https://${vertical}.${this.$env.VUE_APP_DOMAIN}/recorrencia/${recurrence.id}`;
    },
  },
};
</script>

<style scoped>
.min-h-size {
  min-height: 4rem;
}
</style>
