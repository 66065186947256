<template>
  <div class="c-bg-white border-bottom">
    <div class="c-d-flex ui-flex-wrap">
      <!-- Dados -->
      <div class="ui-w-full sm:ui-w-4/5">
        <slot />
      </div>

      <!-- Status -->
      <div class="ui-w-full sm:ui-w-1/5">
        <div
          class="ui-p-4 ui-h-full v-separator"
          :style="{ backgroundColor: `${status.bgColor || 'rgba(var(--c-color-gray-200-rgb), 0.16)'}` }"
        >
          <div class="c-d-flex ui-flex-wrap ui-h-full ui-justify-between c-vertical-center">
            <!-- Texto do Status -->
            <div class="ui-flex-1">
              <c-skeleton-block
                v-if="loading"
                height="1rem"
                width="4rem"
              />
              <div
                v-else
                class="c-text-b2 c-text-gray"
              >
                Status
              </div>

              <c-skeleton-block
                v-if="loading"
                class="ui-mt-2"
                height="1rem"
                width="8rem"
              />

              <c-skeleton-block
                v-if="loading"
                class="ui-mt-2"
                height="1rem"
                width="6rem"
              />
              <div
                v-else
                class="c-text-b1"
                :class="status.textColor || 'c-text-gray'"
              >
                {{ status.text }}
              </div>
            </div>

            <!-- Icone do Status -->
            <div>
              <div>
                <c-skeleton-circle
                  v-if="loading"
                  size="1rem"
                />
                <c-icon
                  v-else
                  :name="status.icon"
                  :color="status.iconColor"
                  width="24"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CIcon, CSkeletonCircle, CSkeletonBlock } from '@estrategiahq/coruja-web-ui';
import { statusOptions } from './status-card-utils';

export default {
  name: 'StatusCard',
  components: {
    CIcon,
    CSkeletonCircle,
    CSkeletonBlock,
  },
  props: {
    loading: { type: Boolean, required: true },
    value: { type: String, required: true },
  },
  computed: {
    status() {
      return statusOptions[this.value] ?? statusOptions.default;
    },
  },
};
</script>

<style scoped>
@media (min-width: 48rem) {
  .border-bottom {
    border-bottom: 1px solid rgba(204, 204, 204, 0.48);
  }

  .v-separator {
    border-left: 1px solid rgba(204, 204, 204, 0.48);
  }
}
</style>
