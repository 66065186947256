<template>
  <div>
    <div class="c-row c-horizontal-center h-full">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10">
        <template v-if="!loading && shouldShowFailedRecurrences">
          <div class="c-text-h3 ui-mb-4">
            Recorrências com falha
          </div>
          <div class="c-text-b2 c-text-gray ui-mb-4">
            Atualize o cartão para continuar com seu acesso.
          </div>

          <div
            v-for="(recurrence, index) in failedRecurrences"
            :key="recurrence.id"
            :class="`ui-mb-${index === failedRecurrences.length - 1 ? 12 : 4}`"
          >
            <recurrence-card
              :key="recurrence.id"
              :recurrence="recurrence"
              :loading="loading"
            />
          </div>
        </template>

        <c-skeleton-block
          v-if="loading"
          height="2rem"
          width="12rem"
          class="ui-mb-4"
        />
        <div
          v-else
          class="c-text-h3 ui-mb-4"
        >
          Minhas Compras
        </div>

        <c-skeleton-block
          v-if="loading"
          height="1rem"
          width="18rem"
          class="ui-mb-4"
        />
        <div
          v-else
          class="c-text-b2 c-text-gray ui-mb-4"
        >
          Acompanhe o status dos seus pedidos
        </div>

        <div class="ui-rounded-lg ui-overflow-hidden ui-space-y-2">
          <!-- Se estiver carregando renderiza 3 order-card como skeleton -->
          <template v-if="loading">
            <order-card
              v-for="i of 3"
              :key="i"
              :order="fakeOrder"
              :loading="true"
            />
          </template>

          <template v-else-if="orders && orders.length > 0">
            <order-card
              v-for="order of orders"
              :key="order.orderNumber"
              :order="order"
              :loading="loading"
            />
          </template>
          <div
            v-else
            class="c-bg-white ui-p-6 c-text-subtitle"
          >
            Você ainda não tem pedidos ou compras realizadas nesta plataforma.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import OrderCard from './components/order-card';
import RecurrenceCard from './components/recurrence-card';
import { OrderStoreKeys } from '@/store/modules/order';
import { CSkeletonBlock } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'MinhasComprasPage',
  components: {
    OrderCard,
    CSkeletonBlock,
    RecurrenceCard,
  },
  data() {
    return {
      fakeOrder: {
        status: '',
        order_items: [],
        order_number: '',
      },
    };
  },
  mounted() {
    this.$store.dispatch(OrderStoreKeys.ACTION_FETCH_LOGGED_USER_ORDERS);
    this.$store.dispatch(OrderStoreKeys.ACTION_FETCH_LOGGED_USER_FAILED_RECURRENCES);
  },
  computed: {
    ...mapGetters({
      orders: OrderStoreKeys.GET_LOGGED_USER_ORDERS,
      failedRecurrences: OrderStoreKeys.GET_LOGGED_USER_FAILED_RECURRENCES,
      loading: OrderStoreKeys.GET_REQUEST_LOADING,
    }),
    shouldShowFailedRecurrences() {
      return this.failedRecurrences.length > 0;
    },
  },
};
</script>
