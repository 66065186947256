<template>
  <div>
    <div class="c-row">
      <div class="c-col-full">
        <status-card
          :loading="loading"
          :value="order.status"
        >
          <!-- Dados -->
          <div class="ui-p-6 c-bg-white">
            <!-- Vertical -->
            <c-skeleton-block
              v-if="loading"
              height="1rem"
              width="8rem"
            />
            <div
              v-else
              class="c-text-b2 c-text-gray"
            >
              {{ order.titleVertical }}
            </div>

            <!-- Nome do produto -->
            <div
              class="c-d-flex c-vertical-center ui-flex-wrap ui-mt-2"
              v-for="item of order.orderItems"
              :key="item.id"
            >
              <div class="ui-w-1 ui-h-1 ui-mr-2 ui-rounded-lg c-bg-primary-200" />
              <span class="c-text-b1">{{ item.description }}</span>
              <c-pill
                v-if="order.isDowngraded(item)"
                outline
                class="ui-ml-2"
                label="RENOVAÇÃO EFETUADA"
                color="var(--c-color-gray)"
              />
              <c-pill
                v-else-if="order.isDowngrade(item)"
                class="ui-ml-2"
                label="RENOVAÇÃO"
              />
              <c-pill
                v-else-if="order.isRenovadoAntecipadamente(item)"
                outline
                class="ui-ml-2"
                label="RENOVAÇÃO EFETUADA"
                color="var(--c-color-gray)"
              />
              <c-pill
                v-else-if="order.isRenovacaoAntecipada(item)"
                class="ui-ml-2"
                label="RENOVAÇÃO ANTECIPADA"
              />
              <c-pill
                v-else-if="order.isUpgraded(item)"
                outline
                class="ui-ml-2"
                label="UPGRADE EFETUADO"
                color="var(--c-color-gray)"
              />
              <c-pill
                v-else-if="order.isUpgrade(item)"
                class="ui-ml-2"
                label="UPGRADE"
              />
            </div>

            <!-- Loader do nome do produto -->
            <template v-if="loading">
              <div class="c-d-flex c-vertical-center ui-mt-2">
                <div class="ui-w-1 ui-h-1 ui-mr-2 ui-rounded-lg c-bg-gray-200" />
                <c-skeleton-block
                  width="14rem"
                  height="1rem"
                />
              </div>
            </template>

            <!-- Separador -->
            <separator />

            <!-- Número do pedido -->
            <div>
              <template v-if="loading">
                <c-skeleton-block
                  height="1rem"
                  width="7rem"
                  class="ui-inline-flex ui-mr-1"
                />
                <c-skeleton-block
                  class="ui-inline-flex"
                  height="1rem"
                  width="6rem"
                />
              </template>
              <span
                v-else
                class="c-text-b2 c-text-gray ui-mr-1"
              >
                Pedido {{ order.orderNumber }}.
              </span>

              <router-link
                :to="{
                  name: 'pedido-detalhes',
                  params: {
                    orderNumber: order.orderNumber,
                    vertical: order.vertical
                  }
                }"
                v-if="!loading"
                class="c-text-hyperlink"
              >
                Ver detalhes
              </router-link>
            </div>
          </div>
        </status-card>
      </div>
    </div>
  </div>
</template>

<script>
import StatusCard from './status-card';
import Separator from '@/components/separator';
import { CSkeletonBlock, CPill } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'OrderCard',
  components: {
    StatusCard,
    Separator,
    CSkeletonBlock,
    CPill,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.min-h-size {
  min-height: 4rem;
}
</style>
